@tailwind base;
@tailwind components;
@tailwind utilities;


body {
   
    background-color: #ffffff;
    color: #000000;
    transition: background-color 0.3s, color 0.3s;
  }
  
  body.dark-mode {
   
    background-color: #121212;
    color: #ffffff;
  }
  
.slider {
  
  margin: 50px;
}
  
