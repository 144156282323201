.App {
  text-align: center;
}

/* App.css */
body {
 
  background-color: #ffffff;
  color: #000000;
  transition: background-color 0.3s, color 0.3s;
}

/* body.dark-mode {
  
  background-color: #121212;
  color: #ffffff;
}

.dark-mode {
  background-color: #111;
  color: #fff;
} */

/* Other styles for your components */









